import React, { useEffect, useState, useMemo, Fragment } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { MenuItem, Select, Tooltip } from '@material-ui/core';

import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
// import ArrowLeft from '../../assets/ArrowLeft.svg';
// import ArrowRight from '../../assets/ArrowRight.svg';
// import ArrowUp from '../../assets/ArrowUp.svg';
// import ArrowDown from '../../assets/ArrowDown.svg';
import { getColor, getNegColor, getRandomValues, manipulateHeatmapTitle } from '../../../../utils/functions';
import colors from '../../../../sass/colors';
import SelectModal from '../../../../components/SelectModal';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
import BulletList from '../../assets/BulletList.svg';
import Down from '../../assets/Down.svg';

function Heatmap({
    filters,
    ieHeatmap,
    ieHeatmapFetched,
    sortIeHeatmap,
    callHeatMap,
    defaultSettings,
    apiLoadingCount,
    errorInfo,
    resurveyModal,
    setReSurveyModal,
    projects,
    getDemographics,
    getBenchmarks,
    heatMapResurveyInfo,
    updateHeatMapInfo,
    getMappedDemographics,
    setReport,
    addOnTab,
    setAddOnTabs,
    appliedFiltersInfo,
    toggleFilterView,
    pptDownload,
    activeResetFilter,
    selectedDemographics,
    setSelectedDemographic,
    updateIds,
}) {
    const { lang = '1033', report_type: surveyType = '', ohid = '', benchmark = '' } = defaultSettings;
    const staticText = getStaticText(lang);
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const {
        // MAX_LENGTH_SUBSTRING,
        SORT_ASCENDING,
        SORT_DESCENDING,
        QUESTIONS_HEATMAP,
        // SLICE_IE_MAX_VALUE,
        // SLICE_IE_MIN_VALUE,
    } = staticTextDiagnose;
    const { SITE_TEXT, IND_EXP_SCORE_CLASSIFICATION, QUES_LEGEND_TYPE_HEATMAP } = staticText;
    const {
        N_SIZE,
        OHI_SCORE_TEXT,
        NUMBER_CAP_TEXT,
        NEGATIVE_PRACTICES,
        EE_PERCENT_FAVORABLE,
        EE_PERCENTILE,
        NOTE,
        IND_EXP_QUESTION_NOTE,
        IND_EXP_LINKS,
        FILTERS,
        DOWNLOAD,
        NUMBER_OF_RESPONDENTS_N,
        EMPLYEE_EXPEIENCE,
        RESURVEY_TEXT,
        EMPLOYEE_FACTORS_POSITIVE,
        EMPLOYEE_FACTORS_NEGATIVE,
    } = SITE_TEXT;
    const { demographics = [] } = filters[0] || {};
    const { header = [], items = [], size = '' } = ieHeatmap || {};
    // const [minSliceValue, setMinSliceValue] = useState(SLICE_IE_MIN_VALUE);
    // const [maxSliceValue, setMaxSliceValue] = useState(SLICE_IE_MAX_VALUE);
    // const [showLeftArrow, setShowLeftArrow] = useState(false);
    // const [showRightArrow, setShowRightArrow] = useState(false);
    const [sortOrder, setSortOrder] = useState({});
    const { selectedProjectIndex: oldIndex = '', heatMapDemo: oldDemo = '' } = heatMapResurveyInfo;

    const selectProject = (selectedProjectIndex, heatMapDemo) => {
        if (selectedProjectIndex !== oldIndex || heatMapDemo !== oldDemo) {
            updateHeatMapInfo({
                selectedProjectIndex,
                heatMapDemo,
            });
        }
        if (selectedProjectIndex !== oldIndex) {
            const { ohid: newId, year: newYear } = projects[selectedProjectIndex];
            getDemographics({ ohid: newId, lang: parseInt(lang, 10) }, 1);
            getBenchmarks({ ohid: newId, year: newYear, lang }, 1, benchmark);
        }
        setReSurveyModal(false);
        setReport(1);
        updateIds([selectedProjectIndex]);
    };

    const headerInclusion = useMemo(() => {
        if (!items.length) return [];
        return [...items.map(({ title = '' }) => title)];
        // eslint-disable-next-line
    }, [ieHeatmap]);

    const dataToUse = useMemo(() => {
        if (!items.length) return [];
        const newArray = [];
        const innerArray = [];
        items.forEach(({ items: subItem = [] }) => {
            innerArray.push(subItem);
        });
        // eslint-disable-next-line no-unused-vars
        header.forEach(i => {
            newArray.push([]);
        });
        for (let i = 0; i < innerArray.length; i += 1) {
            // const { items: subItems } = items
            for (let j = 0; j < header.length; j += 1) {
                newArray[j].push(innerArray[i][j]);
            }
        }

        return newArray;
        // eslint-disable-next-line
    }, [ieHeatmap]);

    const headerToUse = headerInclusion;

    const renderRightOptions = () => {
        // const { demographics = [] } = filters[0] || {};
        return (
            <div className="rightOptions">
                <span>
                    <Select
                        IconComponent={KeyboardArrowDownSharpIcon}
                        value={selectedDemographics}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        onChange={({ target: { value: code } }) => {
                            setSelectedDemographic(code);
                            callHeatMap(code);
                        }}
                    >
                        {demographics.map(({ label, code }) => {
                            return (
                                <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                    {label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </span>
            </div>
        );
    };

    const getHeatMapValues = arrItems => {
        return (
            <Fragment>
                <div>
                    {QUESTIONS_HEATMAP.map(({ children: child = [] }, dataI) => {
                        const itemsToIterate = [];
                        const titleArr = [];
                        child.forEach(elem => {
                            const index = header.indexOf(elem);
                            itemsToIterate.push(arrItems[index]);
                            titleArr.push(header[index]);
                        });
                        // const showCategoryI = dataI === 0 ? 1 : 0;
                        return itemsToIterate.map((subItems, parentI) => {
                            const title = manipulateHeatmapTitle(titleArr[parentI]);
                            // const isN = titleArr[parentI] === NUMBER_CAP_TEXT;
                            // const isNegativeTrait = NEGATIVE_PRACTICES.includes(title);
                            const sup = QUES_LEGEND_TYPE_HEATMAP[title] || '';
                            return (
                                <Fragment key={`${title}${parentI}0`}>
                                    {!dataI && !parentI && (
                                        <div className="sectionDiv headerWrapper">
                                            <ul>
                                                <li className="sectionContent headerList" />
                                                {/* <li className="contentList firstCol bold headerList">{title}</li> */}
                                            </ul>
                                        </div>
                                    )}
                                    <div
                                        className={c(
                                            {
                                                topPadding: parentI === 0 || (!dataI && parentI === child.length - 1),
                                                bottomPadding: parentI === child.length - 1,
                                                borderTop: parentI === 0,
                                                n: title === 'n',
                                            },
                                            'sectionDiv'
                                        )}
                                        key={title}
                                    >
                                        <ul>
                                            <Tooltip placement="top" arrow title={title} aria-label={title}>
                                                <li className="sectionContent">
                                                    {title}
                                                    <sup>{sup}</sup>
                                                </li>
                                            </Tooltip>
                                            {/* {subItems
                                                .slice(minSliceValue, maxSliceValue)
                                                .map(({ score: innerScore }, index) => {
                                                    const i = getColor(innerScore, [], false, true);
                                                    const iNeg = getNegColor(innerScore, [], false, true);
                                                    const {
                                                        positiveBg = '#ffffff',
                                                        negativeBg = '#ffffff',
                                                        color: textColor = '#000000',
                                                    } = IND_EXP_SCORE_CLASSIFICATION[isNegativeTrait ? iNeg : i] || {};
                                                    const bg = isNegativeTrait ? negativeBg : positiveBg;
                                                    const background = !isN ? bg : '#ffffff';
                                                    const color = !isN ? textColor : colors.$darkBlue200;
                                                    return (
                                                        <li
                                                            className={c({ nSize: !i }, 'contentList')}
                                                            key={`${innerScore}${index}${}`}
                                                            style={{
                                                                border: `1px solid white`,
                                                                background,
                                                                color,
                                                            }}
                                                        >
                                                            {!isNaN(parseInt(innerScore, 10))
                                                                ? innerScore.toLocaleString('en-US')
                                                                : '-'}
                                                        </li>
                                                    );
                                                })} */}
                                        </ul>
                                    </div>
                                </Fragment>
                            );
                        });
                    })}
                </div>
                <div className="scrollSection">
                    {QUESTIONS_HEATMAP.map(({ children: child = [] }, dataI) => {
                        const itemsToIterate = [];
                        const titleArr = [];
                        child.forEach(elem => {
                            const index = header.indexOf(elem);
                            itemsToIterate.push(arrItems[index]);
                            titleArr.push(header[index]);
                        });
                        // console.log(headerToUse);
                        // const showCategoryI = dataI === 0 ? 1 : 0;
                        return itemsToIterate.map((subItems, parentI) => {
                            const title = manipulateHeatmapTitle(titleArr[parentI]);
                            const isN = titleArr[parentI] === NUMBER_CAP_TEXT;
                            const isNegativeTrait = NEGATIVE_PRACTICES.includes(title);
                            return (
                                <Fragment key={title + parentI + 1}>
                                    {!dataI && !parentI && (
                                        <div className="sectionDiv headerWrapper">
                                            <ul>
                                                {subItems.map((item, index) => {
                                                    const demoColName = headerToUse[index];
                                                    // titleArr[index].length > 25
                                                    //     ? titleArr[index].slice(0, 20) + '...'
                                                    //     : titleArr[index];
                                                    // console.log(demoColName)
                                                    return (
                                                        <Tooltip title={demoColName + index} key={`header_${index}`}>
                                                            <li className="contentList headerList">{demoColName}</li>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                    <div
                                        className={c(
                                            {
                                                topPadding: parentI === 0 || (!dataI && parentI === child.length - 1),
                                                bottomPadding: parentI === child.length - 1,
                                                borderTop: parentI === 0,
                                                n: title === 'n',
                                            },
                                            'sectionDiv'
                                        )}
                                        key={title}
                                    >
                                        {/* {showCategoryI === parentI ? (
                                                <ul>
                                                    <li className="sectionContent headerContent">{categoryN}</li>
                                                </ul>
                                            ) : null} */}
                                        <ul>
                                            {/* <Tooltip placement="top" arrow title={title} aria-label={title}>
                                                <li className="sectionContent">{title}</li>
                                            </Tooltip> */}
                                            {subItems.map(({ score: innerScore }, index) => {
                                                const i = getColor(innerScore, [], false, true);
                                                const iNeg = getNegColor(innerScore, [], false, true);
                                                const {
                                                    positiveBg = '#ffffff',
                                                    negativeBg = '#ffffff',
                                                    color: textColor = '#000000',
                                                } = IND_EXP_SCORE_CLASSIFICATION[isNegativeTrait ? iNeg : i] || {};
                                                const bg = isNegativeTrait ? negativeBg : positiveBg;
                                                const background = !isN ? bg : '#ffffff';
                                                const color = !isN ? textColor : colors.$darkBlue200;
                                                const border =
                                                    title === 'n' ? '1px solid #e6e6e6' : '1px solid #ffffff';
                                                return (
                                                    <li
                                                        className={c(
                                                            { bold: color === colors.$black100 },
                                                            'contentList'
                                                        )}
                                                        key={`${innerScore}${index}${getRandomValues()}`}
                                                        style={{
                                                            border,
                                                            background,
                                                            color,
                                                        }}
                                                    >
                                                        {!isNaN(parseInt(innerScore, 10))
                                                            ? innerScore.toLocaleString('en-US')
                                                            : '-'}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </Fragment>
                            );
                        });
                    })}
                </div>
            </Fragment>
        );
    };

    const sortData = type => {
        if (type === N_SIZE || type === OHI_SCORE_TEXT) {
            setSortOrder({ [type]: sortOrder[type] === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING });
            const arr = [];
            items.map(({ items: subItems = [] }, i) => {
                const { score = '' } = type === N_SIZE ? subItems[0] || {} : subItems[1] || {};
                arr.push(score + '#' + i);
                return items;
            });
            const sortArr =
                sortOrder[type] === 1
                    ? arr.slice(1, arr.length).sort((a, b) => a.split('#')[0] - b.split('#')[0])
                    : arr.slice(1, arr.length).sort((a, b) => b.split('#')[0] - a.split('#')[0]);
            sortArr.unshift(arr[0]);
            const indexArr = [];
            sortArr.map(val => {
                return indexArr.push(arr.indexOf(val));
            });
            const output = indexArr.map(i => items[i]);
            sortIeHeatmap(output);
        }
    };

    useEffect(() => {
        if (!ieHeatmapFetched) {
            callHeatMap(demographics[0].code);
        }
        // eslint-disable-next-line
    }, [ieHeatmapFetched]);

    // useEffect(() => {
    //     if (items.length - 1 >= maxSliceValue) {
    //         setShowRightArrow(true);
    //     } else {
    //         setShowRightArrow(false);
    //     }
    //     // eslint-disable-next-line
    // }, [maxSliceValue, items]);
    const sup = 1;
    return (
        <Fragment>
            <div className="scoreBoard addOns clearfix">
                <div className="indExpBoard">
                    <div className="rightScorecardHeader">
                        <div className="scorecardHeading">
                            <div className="mainHeading">
                                {EMPLYEE_EXPEIENCE} {resurveyModal ? 'Resurvey' : ''}
                                <sup>{sup}</sup>
                            </div>
                            <div className="nRes">
                                {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                            </div>
                        </div>
                        <ul className="navScoreList">
                            <li
                                data-testid="setReSurveyModalBtn"
                                onClick={() => setReSurveyModal(true)}
                                className="fImage resurveyImg"
                            >
                                <img src={BulletList} alt="" />
                                <span className="upperHead">{RESURVEY_TEXT}</span>
                            </li>
                            {!errorInfo && (
                                <li data-testid="getPptBtn" onClick={pptDownload} className="resurveyImg">
                                    <img src={Down} alt="" />
                                    <span className="lineHead upperHead">{DOWNLOAD}</span>
                                </li>
                            )}
                            <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                                <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                                <span className="filter">{FILTERS}</span>
                            </li>
                        </ul>
                    </div>
                    {appliedFiltersInfo()}

                    <div className={`optionWrapper ${addOnTab === 1 ? 'stickyTop' : ''}`}>
                        <div>
                            <ul className="leftNav">
                                {IND_EXP_LINKS.map((tabName, index) => {
                                    return (
                                        <li
                                            data-testid={tabName}
                                            key={tabName}
                                            className={c({ activeLink: addOnTab === index })}
                                            onClick={() => setAddOnTabs(index)}
                                        >
                                            {tabName}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        {renderRightOptions()}
                    </div>
                    <Fragment>
                        <div className="heatmapBoard clearfix clearPadding">
                            <div className="optionWrapper">
                                <section className={c({ wrapperShadow: headerToUse.length <= 6 }, 'sectionWrapper')}>
                                    <div className="outcomeNames">
                                        {Object.keys(QUESTIONS_HEATMAP).map(elem => {
                                            const { title: name = '', children = [] } = QUESTIONS_HEATMAP[elem];
                                            const len = children.includes('N') ? 1 : children.length;
                                            return (
                                                <div style={{ height: `${len * 7 + 2}vh` }}>
                                                    <span>{name}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="contentWrapper clearfix">
                                        <div
                                            className={c({ noPadding: headerToUse.length <= 6 }, 'mainContent')}
                                            id="myHeader"
                                        >
                                            {dataToUse.length ? getHeatMapValues(dataToUse) : null}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        {resurveyModal && (
                            <SelectModal
                                defaultSettings={defaultSettings}
                                oldSelectedId={oldIndex}
                                oldSelectedDemo={oldDemo}
                                projects={projects}
                                projectsFetched
                                closeModal={setReSurveyModal}
                                selectProject={selectProject}
                                demographics={demographics}
                                current_ohid={ohid}
                                apiLoadingCount={apiLoadingCount}
                                heatMapResurvey
                                getMappedDemographics={getMappedDemographics}
                            />
                        )}
                        {!apiLoadingCount && !errorInfo && (
                            <div className="note">
                                <b>{NOTE}:</b>
                                {surveyType === 'percent favorable' ? <div>1. {EE_PERCENT_FAVORABLE}</div> : ''}
                                {surveyType === 'percentile' ? <div>1. {EE_PERCENTILE}</div> : ''}
                                {IND_EXP_QUESTION_NOTE[0]}
                                <br />
                                {IND_EXP_QUESTION_NOTE[1]}
                                <br />
                            </div>
                        )}
                    </Fragment>
                </div>
            </div>
            <ul className="fixedScoreCardSurvey">
                <ul className="benchmark clearfix">
                    <li>{EMPLOYEE_FACTORS_POSITIVE} : </li>
                    {IND_EXP_SCORE_CLASSIFICATION.map(({ title, positiveBg: background }) => (
                        <li key={title}>
                            <span style={{ background, border: 'none' }} />
                            {title}
                        </li>
                    ))}
                </ul>
                <ul className="benchmark clearfix">
                    <li>{EMPLOYEE_FACTORS_NEGATIVE} : </li>
                    {IND_EXP_SCORE_CLASSIFICATION.map(({ titleNeg: title, negativeBg: background }) => (
                        <li key={title}>
                            <span style={{ background, border: 'none' }} />
                            {title}
                        </li>
                    ))}
                </ul>
            </ul>
        </Fragment>
    );
}

Heatmap.propTypes = {
    filters: PropTypes.array.isRequired,
    ieHeatmap: PropTypes.object.isRequired,
    ieHeatmapFetched: PropTypes.bool.isRequired,
    callHeatMap: PropTypes.func.isRequired,
    sortIeHeatmap: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.number.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    pptDownload: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    setSelectedDemographic: PropTypes.func.isRequired,
    selectedDemographics: PropTypes.string.isRequired,
    resurveyModal: PropTypes.bool.isRequired,
    setReSurveyModal: PropTypes.func.isRequired,
    heatMapResurveyInfo: PropTypes.object.isRequired,
    getMappedDemographics: PropTypes.func.isRequired,
    updateHeatMapInfo: PropTypes.func.isRequired,
    getDemographics: PropTypes.func.isRequired,
    getBenchmarks: PropTypes.func.isRequired,
    setReport: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
    updateIds: PropTypes.array.isRequired,
};

export default Heatmap;
