import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { ArrowBack } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import Lottie from 'react-lottie';
import { getStaticText } from '../../../../utils/constants';
import { getColor, getNegColor } from '../../../../utils/functions';
import animationData from '../../assets/lottie.json';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
// import BulletList from '../../assets/BulletList.svg';
import UpArrow from '../../assets/arrow-up.svg';
import DownArrow from '../../assets/arrow-down.svg';
import SquareMinus from '../../assets/minus-square.svg';
import NoSig from '../../assets/no_significance.svg';

function Resurvey({
    // singleQuestion,
    defaultSettings,
    apiLoadingCount,
    errorInfo,
    addOnTab,
    setAddOnTabs,
    appliedFiltersInfo,
    toggleFilterView,
    // pptDownload,
    activeResetFilter,
    setReport,
    years,
    empExpResurveyData,
}) {
    const { lang, report_type: surveyType = '' } = defaultSettings || {};
    const staticText = getStaticText(lang);
    const { IND_EXP_SCORE_CLASSIFICATION, QUES_LEGEND_TYPE, SITE_TEXT } = staticText;
    const {
        SINGLE_QUESTIONS_LEGEND,
        IND_QUESTIONS_QUES,
        NEGATIVE_PRACTICES,
        // RESPONSE_PERCENT,
        EE_PERCENT_FAVORABLE,
        EE_PERCENTILE,
        NOTE,
        IND_EXP_QUESTION_NOTE,
        IND_EXP_LINKS,
        FILTERS,
        // DOWNLOAD,
        // NUMBER_OF_RESPONDENTS_N,
        EMPLYEE_EXPEIENCE,
        IND_QUES_LEGEND,
        FREQUENCY_LEVEL_PERCENT,
        DIFF_HEAD,
        DIFFERENCE,
        AGGREMENT_LEVEL_PERCENT,
        QUESTIONS_LEGEND,
        EMPLOYEE_FACTORS_NEGATIVE,
        EMPLOYEE_FACTORS_POSITIVE,
        STATISTICAL_DIFFERENCE,
        SCORECARD_RESURVEY_SIGNIFICANCE,
    } = SITE_TEXT || {};
    // const [selectedTab, setSelectedTab] = useState(0);
    // const { SINGLE_QUESTIONS_LEGEND, IND_QUESTIONS_QUES, NEGATIVE_PRACTICES, RESPONSE_PERCENT } = SITE_TEXT[lang] || {};
    const [expandedList, updateExpandedList] = useState([0]);
    const [isExpanded, setIsExpanded] = useState(false);
    const { children: selectedChild = [] } = IND_QUESTIONS_QUES[0];
    const { qbyq: empData = {} } = empExpResurveyData || {};
    const { records: data = {} } = empData || {};
    // const { qbyq: singleQbyq = {}, size = '' } = singleQuestion || {};
    const sigValToUse = SCORECARD_RESURVEY_SIGNIFICANCE;

    const updateExpanded = index => {
        let newList = [...expandedList];
        const currentIndex = expandedList.indexOf(index);
        if (currentIndex !== -1) {
            newList = [...expandedList.slice(0, currentIndex), ...expandedList.slice(currentIndex + 1)];
        } else {
            newList.push(index);
        }
        setIsExpanded(true);
        updateExpandedList(newList);
    };

    const ExpandIcon = () => {
        const defaultOptions = {
            loop: false,
            autoplay: false,
            animationData,
        };

        return (
            <div className="expand-icon">
                <Lottie options={defaultOptions} />
            </div>
        );
    };

    const ExpandIconAnimated = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData,
        };

        const onClickOptions = {
            ...defaultOptions,
            loop: false,
            autoplay: false,
        };

        const handleClick = () => {
            setIsExpanded(true);
        };

        return (
            <div className="expand-icon" onClick={handleClick}>
                <Lottie options={isExpanded ? onClickOptions : defaultOptions} />
            </div>
        );
    };

    const renderIcon = (sig, diff) => {
        const diffToUse = sig;
        if (isNaN(parseInt(sig, 10)) && !isNaN(parseInt(diff, 10))) return NoSig;
        // if (!diff) return null;
        if (diffToUse === 0) {
            return DownArrow;
        }
        if (diffToUse === 1) {
            return UpArrow;
        }
        if (diffToUse === 2) {
            return SquareMinus;
        }
        return null;
    };

    const scoreDifference = (num1, num2) => {
        if (isNaN(parseInt(num1, 10)) || isNaN(parseInt(num2, 10))) {
            return '-';
        }
        return num1 - num2;
    };
    const renderCategoryData = child => {
        return child.map((dataSet, index) => {
            const {
                display_name: title = '',
                meta_scores = [],
                title: childTitle,
                score: [outcomeScore0, outcomeScore1],
                // quartile: [quartile0, quartile1],
                significance = [],
                differences: diffArr = [],
            } = data[dataSet] || {};
            const metas = meta_scores.map(({ meta }) => meta);
            const sup = QUES_LEGEND_TYPE[dataSet] || '';
            const singleQues = sup === 2;
            const isNegativeTrait = NEGATIVE_PRACTICES.includes(title);
            const colorIndex = getColor(outcomeScore0, [], false, true);
            const colorIndexNeg = getNegColor(outcomeScore0, [], false, true);
            const { positiveBg = '#ffffff', negativeBg = '#ffffff', color = '#ffffff' } =
                IND_EXP_SCORE_CLASSIFICATION[isNegativeTrait ? colorIndexNeg : colorIndex] || {};
            const background = isNegativeTrait ? negativeBg : positiveBg;
            const colorIndex1 = getColor(outcomeScore1, [], false, true);
            const colorIndexNeg1 = getNegColor(outcomeScore1, [], false, true);
            const { positiveBg: pBg = '#ffffff', negativeBg: nBg = '#ffffff', color: clr = '#ffffff' } =
                IND_EXP_SCORE_CLASSIFICATION[isNegativeTrait ? colorIndexNeg1 : colorIndex1] || {};
            const background1 = isNegativeTrait ? nBg : pBg;
            const SINGLE_QUESTIONS_LEGEND_TITLE = metas.includes('IENPS1');
            // if (isNaN(parseInt(prScore, 10))) return null;
            return (
                <Accordion classes={{ root: 'accordionRoot' }} key={index}>
                    <AccordionSummary
                        expandIcon={index === 0 ? <ExpandIconAnimated /> : <ExpandIcon />}
                        onClick={() => updateExpanded(index)}
                        data-testid={title}
                        classes={{ content: 'accordionHead' }}
                    >
                        {!SINGLE_QUESTIONS_LEGEND_TITLE ? (
                            <div className="outcomeContent">
                                <div className="metaTotalScore year">
                                    <div className="circle">{years[0]}</div>
                                    <div className="circle">{years[1]}</div>
                                </div>
                                <div className="metaTotalScore">
                                    <div
                                        className="circle"
                                        style={{
                                            background,
                                            color,
                                        }}
                                    >
                                        {outcomeScore0}
                                    </div>
                                    <div
                                        className="circle"
                                        style={{
                                            background: background1,
                                            color: clr,
                                        }}
                                    >
                                        {outcomeScore1}
                                    </div>
                                    <div className="diff">
                                        {diffArr[0]}
                                        <span
                                            className={c('difference', {
                                                negative: diffArr[0] < 0 && significance[0] === '',
                                            })}
                                        >
                                            <img src={renderIcon(significance[0], diffArr[0])} alt="" />
                                        </span>
                                    </div>
                                </div>
                                {title} <sup>{sup}</sup>
                                <ul className={c({ ohi4Legends: true }, 'questionLegends')}>
                                    <li className="listTitle">{AGGREMENT_LEVEL_PERCENT}</li>
                                    <li>
                                        <span />
                                        {QUESTIONS_LEGEND[2]}
                                    </li>
                                    <li className="listTitle">{DIFF_HEAD}</li>
                                    {DIFFERENCE.map(({ legendText, color: cl }) => (
                                        <li key={legendText}>
                                            <span style={{ background: cl }} />
                                            {legendText}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <Fragment>
                                {!singleQues ? (
                                    <div className="outcomeContent">{title}</div>
                                ) : (
                                    <div className="outcomeContent">
                                        <div className="metaTotalScore year">
                                            <div className="circle">{years[0]}</div>
                                            <div className="circle">{years[1]}</div>
                                        </div>
                                        <div className="metaTotalScore">
                                            <div
                                                className="circle"
                                                style={{
                                                    background,
                                                    color,
                                                }}
                                            >
                                                {outcomeScore0}
                                            </div>
                                            <div
                                                className="circle"
                                                style={{
                                                    background: background1,
                                                    color: clr,
                                                }}
                                            >
                                                {outcomeScore1}
                                            </div>
                                            <div className="diff">
                                                {diffArr[0]}
                                                <span
                                                    className={c('difference', {
                                                        negative: diffArr[0] < 0 && significance[0] === '',
                                                    })}
                                                >
                                                    <img src={renderIcon(significance[0], diffArr[0])} alt="" />
                                                </span>
                                            </div>
                                        </div>
                                        {title} <sup>{sup}</sup>
                                        <ul className={c({ ohi4Legends: true }, 'questionLegends')}>
                                            <li className="listTitle">Promoters in %</li>
                                            <li>
                                                <span />
                                                {SINGLE_QUESTIONS_LEGEND[2]}
                                            </li>
                                            <li className="listTitle">{DIFF_HEAD}</li>
                                            {DIFFERENCE.map(({ legendText, color: cl }) => (
                                                <li key={legendText}>
                                                    <span style={{ background: cl }} />
                                                    {legendText}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: 'clearfix' }}>
                        <section className="leftSection">
                            <section className="metaSection clearfix metaHeader">
                                <div className="metaText" />
                                <div className={c({ ohi4Meta: true }, 'metaScore')}>
                                    <div className="resurveyMeta">{years[0]}</div>
                                    <div className="delta">Delta</div>
                                    <div className="resurveyMeta">{years[1]}</div>
                                </div>
                            </section>
                            {meta_scores.map(item => {
                                const {
                                    qtext: metaPracticeText = '',
                                    // bottom2_score = 0,
                                    // nuetral_score = 0,
                                    // na_score = 0,
                                    score: [practiceScore0, practiceScore1],
                                } = item || {};
                                // const topScore = 100 - bottom2_score - nuetral_score - na_score;
                                // const totalScore = bottom2_score + nuetral_score + topScore;
                                return (
                                    <div key={childTitle} className="clearfix">
                                        <section key={metaPracticeText} className="metaSection clearfix">
                                            <div className="metaText">
                                                {metaPracticeText}
                                                <sup>{sup}</sup>
                                            </div>
                                            {practiceScore0 ? (
                                                <div
                                                    className={c(
                                                        {
                                                            ohi4Meta: true,
                                                        },
                                                        'metaScore'
                                                    )}
                                                >
                                                    <div className="resurveyMeta">
                                                        {!isNaN(practiceScore0) && (
                                                            <span
                                                                style={{
                                                                    width: `${practiceScore0}%`,
                                                                }}
                                                            >
                                                                {practiceScore0}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div
                                                        className={c(
                                                            {
                                                                neg: practiceScore0 < practiceScore1,
                                                                neutral: practiceScore0 === practiceScore1,
                                                                pos: practiceScore0 > practiceScore1,
                                                            },
                                                            'delta'
                                                        )}
                                                    >
                                                        <span>{scoreDifference(practiceScore0, practiceScore1)}</span>
                                                    </div>
                                                    <div className="resurveyMeta">
                                                        {!isNaN(practiceScore1) && (
                                                            <span
                                                                style={{
                                                                    width: `${practiceScore1}%`,
                                                                }}
                                                            >
                                                                {practiceScore1}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </section>
                                    </div>
                                );
                            })}
                        </section>
                    </AccordionDetails>
                </Accordion>
            );
        });
    };

    return (
        <Fragment>
            <div className="scoreBoard addOns clearfix">
                <div className="indExpBoard">
                    <div className="breadCrumb">
                        <span data-testid="setScoreFromBuBtn" onClick={() => setReport(0)}>
                            Questions
                        </span>{' '}
                        {' > '}
                        {'Resurvey'}
                    </div>
                    <h2 className="clearfix">
                        <div className="rightScorecardHeader">
                            <span data-testid="setBackFromBuBtn" onClick={() => setReport(0)}>
                                <ArrowBack />
                                Resurvey {EMPLYEE_EXPEIENCE}
                            </span>
                            <ul className="navScoreList">
                                {/* <li data-testid="getPptBtn" onClick={pptDownload} className="resurveyImg">
                                    <img src={Down} alt="" />
                                    <span className="lineHead upperHead">{DOWNLOAD}</span>
                                </li> */}
                                <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                                    <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                                    <span className="filter">{FILTERS}</span>
                                </li>
                            </ul>
                        </div>
                    </h2>
                    {appliedFiltersInfo()}

                    <div className={`optionWrapper ${addOnTab === 1 ? 'stickyTop' : ''}`}>
                        <div>
                            <ul className="leftNav">
                                {IND_EXP_LINKS.map((tabName, index) => {
                                    return (
                                        <li
                                            data-testid={tabName}
                                            key={tabName}
                                            className={c({ activeLink: addOnTab === index })}
                                            onClick={() => setAddOnTabs(index)}
                                        >
                                            {tabName}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="outcomeContent">
                            <ul className="questionLegends">
                                <li>{FREQUENCY_LEVEL_PERCENT}</li>
                                {IND_QUES_LEGEND.map(legendText => (
                                    <li>
                                        <span />
                                        {legendText}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <Fragment>
                        {Object.keys(data).length ? (
                            <div className="questionBoard resurveyBoard clearfix">
                                {renderCategoryData(selectedChild)}
                            </div>
                        ) : null}

                        {!apiLoadingCount && !errorInfo && (
                            <div className="note">
                                <b>{NOTE}:</b>
                                {surveyType === 'percent favorable' ? <div>1. {EE_PERCENT_FAVORABLE}</div> : ''}
                                {surveyType === 'percentile' ? <div>1. {EE_PERCENTILE}</div> : ''}
                                {IND_EXP_QUESTION_NOTE[0]}
                                <br />
                                {IND_EXP_QUESTION_NOTE[1]}
                                <br />
                            </div>
                        )}
                    </Fragment>
                </div>
            </div>
            <ul className="fixedScoreCardSurvey">
                <ul className="benchmark clearfix">
                    <li>{EMPLOYEE_FACTORS_POSITIVE} : </li>
                    {IND_EXP_SCORE_CLASSIFICATION.map(({ title, positiveBg: background }) => (
                        <li key={title}>
                            <span style={{ background, border: 'none' }} />
                            {title}
                        </li>
                    ))}
                </ul>
                <ul className="benchmark clearfix">
                    <li>{EMPLOYEE_FACTORS_NEGATIVE} : </li>
                    {IND_EXP_SCORE_CLASSIFICATION.map(({ titleNeg: title, negativeBg: background }) => (
                        <li key={title}>
                            <span style={{ background, border: 'none' }} />
                            {title}
                        </li>
                    ))}
                </ul>
                <ul className="benchmark clearfix change">
                    <li>{STATISTICAL_DIFFERENCE} : </li>
                    {sigValToUse.map(({ title, imgSrc }) => (
                        <li key={title}>
                            <span>
                                <img src={imgSrc} alt="" />
                            </span>
                            {title}
                        </li>
                    ))}
                </ul>
            </ul>
        </Fragment>
    );
}

Resurvey.propTypes = {
    // singleQuestion: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.number.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    // pptDownload: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setReport: PropTypes.func.isRequired,
    years: PropTypes.array.isRequired,
    empExpResurveyData: PropTypes.object.isRequired,
};

export default Resurvey;
